<template>
  <v-app>
    <nav-bar></nav-bar>
    <v-main class="zindex">
      <router-view />
      <div class="watermark">Meeting Me</div>
      <Footer></Footer>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
export default {
  components: { NavBar, Footer },
  name: "App",
};
</script>
'
<style lan="scss">
.zindex {
  z-index: 3;
}
.watermark {
  position: fixed;
  bottom: 115px;
  right: 115px;
  opacity: 0.5;
  z-index: 1;
  color: rgb(202, 190, 190);
}
</style>
