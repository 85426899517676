import api from "../services/api";
export default {
  namespaced: true,
  state: {
    access: localStorage.getItem("access") || null,
    refresh: localStorage.getItem("refresh") || null,
    username: "",
    first_name: "",
    last_name: "",
    user_type: "",
    authenticated: false,
    is_verified: "",
    is_active: "",
  },
  actions: {
    login({ commit, dispatch }, { phone, password, country_code }) {
      return new Promise((resolve, reject) => {
        return api
          .post("users/token/", {
            phone: phone,
            password: password,
            country_code: country_code,
          })
          .then((res) => {
            const { access, refresh } = res.data;
            commit("loadTokens", { access, refresh });
          })
          .then(() => dispatch("validate").then(() => resolve()))
          .catch((err) => reject(err));
      });
    },
    register({ dispatch }, { phone, password, country }) {
      return new Promise((resolve, reject) =>
        api
          .post("users/", {
            phone: phone,
            password: password,
            country: country,
          })
          .then(() =>
            dispatch("login", {
              phone: phone,
              password: password,
              country: country,
            }).then(() => resolve())
          )
          .catch((err) => {
            reject(err.data);
          })
      );
    },
    validate({ state, commit, dispatch }) {
      return new Promise((resolve, reject) =>
        api
          .post("users/verify/", null, {
            headers: { Authorization: `Bearer ${state.access}` },
          })
          .then((res) =>
            commit("loadUser", {
              id: res.data.id,
              username: res.data.username,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              user_type: res.data.user_type,
              is_active: res.data.is_active,
              is_verified: res.data.is_verified,
            })
          )
          .then(() => resolve())
          .catch((err) => {
            dispatch("logout");
            console.log("ss");
            reject(err);
          })
      );
    },
    logout({ commit }) {
      commit("clear");
    },
  },
  mutations: {
    loadTokens(state, { access, refresh }) {
      state.access = access;
      state.refresh = refresh;
      localStorage.setItem("access", access);
      localStorage.setItem("refresh", refresh);
    },
    loadUser(
      state,
      { id, username, first_name, last_name, user_type, is_active, is_verified }
    ) {
      state.username = username;
      state.authenticated = true;
      state.first_name = first_name;
      state.last_name = last_name;
      state.user_type = user_type;
      state.is_active = is_active;
      state.is_verified = is_verified;
      state.id = id;
    },
    clear(state) {
      state.access = null;
      state.refresh = null;
      state.username = "";
      state.first_name = "";
      state.last_name = "";
      state.user_type = "";
      state.authenticated = false;
      state.is_verified = "";
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      api.defaults.headers["Authorization"] = "";
    },
  },
  getters: {
    getAccessToken: (state) => state.access,
    getRefreshToken: (state) => state.refresh,
    getUsername: (state) => state.username,
    userIsAuthenticated: (state) => state.authenticated,
    getUserId: (state) => state.id,
    getName: (state) => state.first_name + " " + state.last_name,
    getEmail: (state) => state.email,
    getUserType: (state) => state.user_type,
    isOrganization: (state) => state.user_type === "ORGANIZATION",
    isAdmin: (state) => state.user_type === "ADMIN",
    isTeacher: (state) => state.user_type === "TEACHER",
    getAdminID: (state) => state.user_type === "ADMIN" && state.id,
    getOrganizationID: (state) =>
      state.user_type === "ORGANIZATION" ? state.id : null,
    isRegular: (state) => ["REGULAR", "STUDENT"].includes(state.user_type),
    isVerified: (state) => state.is_verified,
  },
};
